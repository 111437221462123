<div class="max-w-lg pt-4">
  @if (data.headline) {
    <div class="mb-3 pt-8 text-center text-3xl font-bold">
      {{ data.headline }}
    </div>
  }
  <div class="mb-2 text-center">
    <mat-dialog-content>
      <div>
        {{ data.description }}
        @if (data.linkDescription) {
          <app-link
            [href]="data.linkHref"
            [description]="data.linkDescription"
          ></app-link>
        }
      </div>
    </mat-dialog-content>
  </div>
  <div
    class="flex w-full items-center justify-center rounded-b-lg bg-gray-100 text-center"
  >
    <div class="flex w-full flex-col items-center p-2 px-4">
      @if (data.footerDescription) {
        <div class="p-2 text-sm font-normal text-grey">
          {{ data.footerDescription }}
          @if (data.footerLinkDescription) {
            <app-link
              [description]="data.footerLinkDescription"
              [clickAction]="data.footerLinkAction"
            ></app-link>
          }
        </div>
      }

      @if (data.additionalFooterDescription) {
        <div class="p-2 text-sm font-normal text-grey">
          {{ data.additionalFooterDescription }}
          @if (data.additionalFooterLinkDescription) {
            <app-link
              [description]="data.additionalFooterLinkDescription"
              [clickAction]="data.additionalFooterLinkAction"
            ></app-link>
          }
        </div>
      }

      @if (data.buttonRowData) {
        <app-button-row
          class="w-48"
          [primaryButtonText]="data.buttonRowData.confirmButtonText"
          (primaryButtonClickedEvent)="confirmButtonClick()"
          [secondaryButtonText]="data.buttonRowData.declineButtonText"
          (secondaryButtonClickedEvent)="closeDialog()"
        ></app-button-row>
      }

      @if (data.confirmButtonText && !data.buttonRowData) {
        <app-primary-button
          class="w-3/4 p-2"
          [text]="data.confirmButtonText"
          (buttonClicked)="confirmButtonClick()"
        ></app-primary-button>
      }
    </div>
  </div>
</div>
