import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import {
  CourseEnrollmentRequestDTO,
  CourseEnrollmentResponseDTO,
  CourseInstanceDetailResponseDTO,
  CourseInstancesResponseDTO,
  CourseSendReminderRequestDTO,
  CourseSendReminderResponseDTO,
  CourseUnenrollmentRequestDTO,
  CourseUnenrollmentResponseDTO,
} from '../models/CourseDTO';
import {
  toCourseEnrollmentRequestDTO,
  toCourseUnenrollmentRequestDTO,
  toSendReminderRequestDTO,
} from '../mappers/dto-mappers';
import { CurrentUserState } from '../../../core/store/current-user/current-user.state';
import { PortalApiRepository } from '../../../core/repositories/portal-api-repository';
import { EnrolledStudentUIModel } from '../models/StudentUIModel';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CourseService extends PortalApiRepository {
  constructor() {
    super();
  }

  getCourseInstances(): Observable<CourseInstancesResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/CourseInstances`;

    return this.get<CourseInstancesResponseDTO>(path);
  }

  getCourseDetail(id: string): Observable<CourseInstanceDetailResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/CourseInstances/${id}`;

    return this.get<CourseInstanceDetailResponseDTO>(path);
  }

  enrollStudentsToCourse(
    students: Array<{ id: string }>,
    courseInstanceId: string,
  ): Observable<CourseEnrollmentResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/CourseInstances/${courseInstanceId}/enroll`;
    const payload = toCourseEnrollmentRequestDTO(students);

    return this.post<CourseEnrollmentRequestDTO, CourseEnrollmentResponseDTO>(path, payload);
  }

  unenrollStudentsFromCourse(
    students: EnrolledStudentUIModel[],
    courseInstanceId: string,
  ): Observable<CourseUnenrollmentResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/CourseInstances/${courseInstanceId}/unenroll`;
    const payload = toCourseUnenrollmentRequestDTO(students);

    return this.post<CourseUnenrollmentRequestDTO, CourseUnenrollmentResponseDTO>(path, payload);
  }

  sendReminderToStudentsFromCourse(
    students: EnrolledStudentUIModel[],
    courseInstanceId: string,
  ): Observable<CourseSendReminderResponseDTO> {
    const path = `/send-reminder/manual`;
    const payload = toSendReminderRequestDTO(students, courseInstanceId);

    return this.post<CourseSendReminderRequestDTO, CourseSendReminderResponseDTO>(path, payload);
  }

  exportStudentInfoToCsv(courseInstanceId: string): Observable<Blob> {
    const path = '/CourseUserActivityHistory/History';
    const { createdAt, expiresAt } = this.store.selectSnapshot(CurrentUserState.subscriptionBillingPeriod);

    if (!createdAt || !expiresAt) {
      console.error('Subscription Cannot be found');
      return EMPTY;
    }

    const queryParams = new HttpParams()
      .set('startDate', createdAt)
      .set('endDate', expiresAt)
      .set('courseInstanceId', courseInstanceId);

    return this.download(path, queryParams);
  }
}
