import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CourseEnrollmentNotificationService {
  private readonly enrollmentChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getEnrollmentChanged(): Observable<boolean> {
    return this.enrollmentChangedSubject.asObservable();
  }

  setEnrollmentChanged(): void {
    this.enrollmentChangedSubject.next(true);
  }
}
