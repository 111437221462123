import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { CurrentUserState } from '../../../core/store/current-user/current-user.state';
import { PortalApiRepository } from '../../../core/repositories/portal-api-repository';
import { SubscriptionLicensesResponseDTO } from '../models/SubscriptionDTO';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends PortalApiRepository {
  constructor() {
    super();
  }

  getLicenses(): Observable<SubscriptionLicensesResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/Licenses`;

    return this.get<SubscriptionLicensesResponseDTO>(path);
  }
}
