import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  SepImpersonationSessionData,
  SepSessionStorageKeys,
} from '../../../core/services/session-storage/SepSessionStorage';
import { SessionStorageService } from '../../../core/services/session-storage/session-storage.service';
import { OrganizationDTO } from '../organizations/models/OrganizationDTO';

@Injectable({
  providedIn: 'root',
})
export class SepAdminService {
  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly router: Router,
  ) {}

  startImpersonation(organization: OrganizationDTO): void {
    const impersonationSessionData: SepImpersonationSessionData = { organization: organization };
    this.sessionStorageService.setItem(SepSessionStorageKeys.SEP_IMPERSONATION_SESSION_DATA, impersonationSessionData);
    void this.router.navigate(['/portal']);
  }

  stopImpersonation(): void {
    this.sessionStorageService.removeItem(SepSessionStorageKeys.SEP_IMPERSONATION_SESSION_DATA);
    void this.router.navigate(['/admin/customers']);
  }
}
