export enum LicenseStatus {
  AVAILABLE = 'Available',
  CONSUMED = 'Consumed',
  RESERVED = 'Reserved',
}

export class LicensesResponseDTO {
  id: string;
  status: LicenseStatus;
}
export class SubscriptionLicensesResponseDTO {
  licenses: LicensesResponseDTO[];
}
